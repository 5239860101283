import React, { useEffect } from "react";
import "./AllProfiles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProfileAction,
  deleteProfileAction,
  changeProfileAction,
} from "logic";

function AllProfiles({ toggleModal }: any) {
  const dispatch = useDispatch();
  const { data } = useSelector((state: any) => state.profileReducer);

  useEffect(() => {
    dispatch(getAllProfileAction());
  }, [toggleModal]);

  const activeProfilePicture = data?.find(
    (profile: any) => profile.is_active && profile.image_url
  )?.image_url;

  const handleDelete = (id: string) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      dispatch(deleteProfileAction(id));
    }
  };

  const changeProfile = (id: string) => {
    dispatch(changeProfileAction(id));
    toggleModal();
  };

  return (
    <div className="all_profiles">
      {data?.map((profile: any) => (
        <div
          onClick={() => changeProfile(profile.id)}
          key={profile.id}
          className={`profile_card ${
            profile.image_url === activeProfilePicture ? "active_profile" : ""
          }`}
        >
          <img src={profile?.image_url} alt="Profile" />
          {profile.image_url === activeProfilePicture && (
            <div className="check_mark">✅</div>
          )}
          <button
            className="delete_button"
            onClick={() => handleDelete(profile.id)}
          >
            &times;
          </button>
        </div>
      ))}
    </div>
  );
}

export default AllProfiles;
