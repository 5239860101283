import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVideoAction, deleteVideoAction, uploadVideoAction } from "logic";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

interface ProcessedVideo {
  uri: string | Blob | File;
  type: string;
  name: string;
  isNative?: boolean;
}

function IntroVideo() {
  const [hovered, setHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  const dispatch = useDispatch();
  const { data } = useSelector((state: any) => state.introVideoReducer);

  useEffect(() => {
    dispatch(getAllVideoAction());
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(deleteVideoAction());
    window.location.reload();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setUploadProgress(0);
    try {
      const video: ProcessedVideo = {
        uri: selectedFile,
        type: selectedFile.type,
        name: selectedFile.name,
        isNative: false,
      };

      await dispatch(
        uploadVideoAction(video, (progress) => {
          setUploadProgress(progress);
        })
      );
      window.location.reload();
      setShowModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setUploadProgress(null);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        border: "1px solid #ccc",
        marginTop: "20px",
        height: "100%",
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0 0 9px 1px rgba(0,0,0,0.1)",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {data?.length > 0 ? (
        <video
          width="100%"
          height="100%"
          controls
          style={{ objectFit: "cover", borderRadius: "10px" }}
        >
          {data[0]?.introvideourl ? (
            <source src={data[0]?.introvideourl} type="video/mp4" />
          ) : (
            <p>Video URL is not available</p>
          )}
          Your browser does not support the video tag.
        </video>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>Not found</span>
          <div
            className="seperate-upload-icon"
            onClick={() => setShowModal(true)}
          >
            <i className="fa fa-upload"></i>
          </div>
        </div>
      )}
      {hovered && data?.length > 0 && (
        <>
          <div className="icon-cross" onClick={handleDelete}>
            <i className="fa fa-trash"></i>
          </div>
          <div className="icon-upload" onClick={() => setShowModal(true)}>
            <i className="fa fa-refresh"></i>
          </div>
        </>
      )}

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          Upload Video
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="fileInput">Choose a video file</Label>
              <Input
                type="file"
                id="fileInput"
                accept="video/*"
                onChange={handleFileChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleUpload}
            disabled={uploadProgress !== null}
          >
            {uploadProgress !== null ? "Uploading..." : "Upload"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default IntroVideo;
