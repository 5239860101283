import React, { useEffect } from "react";
import { getActiveProfileAction } from "logic";
import { useDispatch } from "react-redux";
import Avatar from "../../assets/img/avatar.png";
function Profile({ toggleModal, activeProfilePicture }: any) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveProfileAction());
  }, [dispatch]);
  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <img
        src={activeProfilePicture || Avatar}
        alt="profile picture"
        style={{
          borderRadius: "50%",
          width: "60px",
          height: "60px",
        }}
      />
      <div className="profile-link-container">
        <span
          className="see-all-link"
          style={{
            color: "dodgerblue",
            cursor: "pointer",
            textAlign: "center",
            textDecoration: "underline",
          }}
          onClick={toggleModal}
        >
          see all profile pictures
        </span>
      </div>
    </div>
  );
}

export default Profile;
